function globalEmit(key: string, emittedValue?: Record<string, any> | string | boolean | number | undefined) {
  (globalThis as any)?.__EMITTER__?.emit(key, emittedValue);
}

function globalListener(key: string, listener: (param: any) => void) {
  (globalThis as any)?.__EMITTER__?.on(key, listener);
}

function toFirstUpperCase(str: string) {
  if (!str) return '';
  const firstLetter = str.substring(0, 1);
  const remains = str.toLowerCase().substring(1, str.length);
  return `${firstLetter.toUpperCase()}${remains}`;
}

function getAssetFromCdn(path = '') {
  const cdnBase = ((globalThis as any)?.__CDN_URL || '').replace(/\/$/, '');
  const assetPath = path.replace(/^\//, '');
  return [cdnBase, assetPath].join('/');
}

function debounce(func: any, waitFor = 300) {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func.apply(this, args), waitFor);
  };
}

function plainChars(str: string) {
  const mask = {
    Ü: 'U',
    ü: 'u',
    Ö: 'O',
    ö: 'o',
    İ: 'i',
    I: 'i',
    ı: 'i',
    Ş: 'S',
    ş: 's',
    Ğ: 'G',
    ğ: 'g',
    Ç: 'C',
    ç: 'c'
  };
  Object.entries(mask).forEach(([char, replaceChar]) => {
    str = str.replace(new RegExp(char, 'g'), replaceChar);
  });

  return str;
}

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
}

function hexToRgba(colorHex:string, opacityPercent = 100) {
  const colorRGB = hexToRgb(colorHex || '');
  return colorRGB ? `rgba(${colorRGB?.r},${colorRGB?.g},${colorRGB?.b}, ${opacityPercent}%)` : `rgba(0,0,0,0)`
}

function getClientNumber(value: number, countryCode: string) {
  return new Intl.NumberFormat(countryCode, { style: 'decimal' }).format(value);
}
function getClientNumberFormat(countryCode: string) {
  const value = getClientNumber(123456.789, countryCode).split('');
  const thousandsSeparator = value.find((val) => isNaN(+val)) || '.';
  const decimalSeperator = value.reverse().find((val) => isNaN(+val)) || ',';
  const options = new Intl.NumberFormat(countryCode, { style: 'decimal' }).resolvedOptions();
  return {
    scale: options.maximumFractionDigits,
    radix: decimalSeperator,
    thousandsSeparator,
    mapToRadix: [decimalSeperator],
    normalizeZeros: true
  }
}
function getRealNumber(value: string | number, countryCode: string) {
  const format = getClientNumberFormat(countryCode)
  const thousandsSeparator = new RegExp(`\\${format.thousandsSeparator}`, 'g');
  const decimalSeperator = new RegExp(`\\${format.radix}`, 'g');
  const newValue = `${value}`.replace(thousandsSeparator, '').replace(decimalSeperator, '.') || '0'
  // Be sure this is a valid number
  return parseFloat(`${newValue}`);
}

function isJSON(text: string) {
  try {
    JSON.parse(text);
    return true;
  } catch {
    return false;
  }
}

export { toFirstUpperCase, globalEmit, globalListener, getAssetFromCdn, debounce, plainChars, hexToRgb, hexToRgba, getClientNumber, getClientNumberFormat, getRealNumber, isJSON };
